import Head from 'next/head'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from '../theme'
import { useRouterScroll, useRouterRedirect } from '../hooks'
import '../styles/index.css'
import '../styles/post.css'
import { useEffect } from 'react'
import * as gtag from '../utils/gtag'

NProgress.configure({ showSpinner: false })

Router.events.on('routeChangeStart', () => {
  NProgress.start()
})
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({ Component, pageProps }) {
  useRouterRedirect()
  useRouterScroll()

  const GenerateCanonicalMeta = () => {
    const router = useRouter()
    const { pathname, query, asPath } = router
    let canonicalMeta = null

    const link = asPath.split('?')[0]
    let alternateLink = null
    const seperatedPath = pathname.split('/')
    if (seperatedPath.includes('[page]')) {
      const seperatedAsPath = asPath.split('/')
      const page = seperatedAsPath[seperatedAsPath.length - 1].split('?')[0]
      if (page !== '1') {
        alternateLink = link.replace(`/${page}`, '')
        canonicalMeta = (
          <>
            <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${alternateLink}`} />
            <link rel='alternate' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
          </>
        )
        return canonicalMeta
      }
    }

    canonicalMeta = <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
    if (alternateLink) {
      canonicalMeta = (
        <>
          <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
          <link rel='alternate' href={`${process.env.NEXT_PUBLIC_HOST}${alternateLink}`} />
        </>
      )
    }

    if (Object.keys(query).length > 0) {
      if (alternateLink) {
        if (link === asPath) {
          canonicalMeta = (
            <>
              <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
              <link rel='alternate' href={`${process.env.NEXT_PUBLIC_HOST}${alternateLink}`} />
            </>
          )
        } else {
          canonicalMeta = (
            <>
              <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
              <link rel='alternate' href={`${process.env.NEXT_PUBLIC_HOST}${alternateLink}`} />
              <link rel='alternate' href={`${process.env.NEXT_PUBLIC_HOST}${asPath}`} />
            </>
          )
        }
      } else {
        if (link === asPath) {
          canonicalMeta = (
            <>
              <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
            </>
          )
        } else {
          canonicalMeta = (
            <>
              <link rel='canonical' href={`${process.env.NEXT_PUBLIC_HOST}${link}`} />
              <link rel='alternate' href={`${process.env.NEXT_PUBLIC_HOST}${asPath}`} />
            </>
          )
        }
      }
    }
    return canonicalMeta
  }

  const router = useRouter()

  useEffect(() => {
    const handleRouteChange = url => {
      if (typeof window !== 'undefined') {
        try {
          window._hmt.push(['_trackPageview', url])
          gtag.pageview(url)
        } catch (e) {
          /* eslint-disable no-console */
          console.log('e: ', e)
          /* eslint-enable no-console */
        }
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <>
      <Head>
        <link rel='manifest' href='/manifest.json' />
        <link rel='icon' type='image/jpg' sizes='96x96' href='https://static.letschuhai.com/static/images/fav.jpg' />
        <meta charSet='utf-8' />
        <meta name='theme-color' content='#ffffff' />
        <meta property='og:site' content='letschuhai.com' key='site' />
        <meta property='og:site_name' content='36氪出海' key='site_name' />
        <meta property='twitter:card' content='summary_large_image' key='twitter_card' />
        <meta name='mobile-web-app-capable' content='yes' />
        <meta name='apple-mobile-web-app-capable' content='yes' />
        <meta name='application-name' content='36氪出海' />
        <meta name='apple-mobile-web-app-title' content='36氪出海' />
        <meta
          property='og:image'
          key='og:image'
          content='https://static.letschuhai.com/static/images/icons/android-icon-192x192.png'
        />
        {pageProps.is404 && (
          <>
            <title>页面不存在</title>
            <meta name='robots' content='noindex, nofollow' key='robots' />
          </>
        )}
        {GenerateCanonicalMeta()}
      </Head>
      <ChakraProvider resetCSS={true} theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
    </>
  )
}

export default MyApp
