import { theme as coreTheme } from '@krasia/core/theme'
import { mergeWith } from '@chakra-ui/utils'

const chineseTheme = mergeWith(coreTheme, {
  breakpoints: {
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    xll: '90em'
  },
  styles: {
    global: {
      body: {
        fontFamily: "'Noto Sans TC', sans-serif",
        backgroundColor: 'bg',
        color: 'text'
      }
    }
  },
  textStyles: {
    navLink: {
      color: 'navTextSecondary'
    },
    mobileNavLink: {
      color: '#333'
    },
    bannerCategory: {
      color: 'articleCategory'
    },
    sectionTitle: {
      color: 'sectionTitle'
    },
    sectionTitleFeature: {
      color: 'sectionTitleFeature',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: {
        base: '24px',
        md: '26px'
      },
      lineHeight: {
        base: '28px'
      }
    },
    sectionArticleSeeAll: {
      color: 'articleSeeAll'
    },
    sectionArticleCategory: {
      color: 'articleCategory'
    },
    sectionArticleGridCategory: {
      color: 'articleCategory'
    },
    collectionItemCategory: {
      color: 'articleCategory'
    },
    articleCategory: {
      color: 'articleCategory'
    },
    footerLink: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: {
        base: '18px'
      },
      lineHeight: '32px'
    },
    pageTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: {
        base: '40px',
        md: '56px'
      },
      lineHeight: {
        base: '48px',
        md: '64px'
      }
    },
    subTitle: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: {
        base: '18px'
      },
      lineHeight: {
        base: '72px'
      }
    },
    onBoardingTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: {
        base: '36px',
        md: '46px'
      },
      lineHeight: {
        base: '44px',
        md: '58px'
      }
    },
    onBoardingSubTitle: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      mt: '15px',
      fontSize: {
        base: '18px'
      },
      lineHeight: {
        base: '24px'
      }
    },
    formLabel: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '21px',
      lineHeight: '32px'
    },
    authorTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: {
        base: '32px',
        md: '32px'
      },
      lineHeight: {
        base: '32px',
        md: '32px'
      }
    },
    profileTitle: {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: {
        base: '26px',
        lg: '55px'
      },
      lineHeight: {
        base: '30px',
        lg: '72px'
      }
    },
    articleItemAuthor: {
      fontSize: '12px',
      lineHeight: '14px',
      color: 'articleItemAuthor'
    },
    articleText: {
      color: 'articleText'
    },
    articleIntro: {
      color: '#bdc4c8',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: {
        base: '18px',
        md: '18px'
      },
      lineHeight: {
        base: '32px',
        md: '36px'
      }
    }
  },
  components: {
    Input: {
      variants: {
        borderlessFooter: {
          field: {
            borderBottom: '1px solid #66FCF1',
            color: '#1F2833'
          }
        },
        borderlessHeader: {
          field: {
            borderBottom: '1px solid #FFF',
            color: '#1F2833',
            _hover: {
              borderColor: '#FFF'
            }
          }
        },
        borderless: {
          field: {
            backgroundColor: 'transparent',
            borderBottom: '1px solid #A5A7AA',
            color: '#ccc'
          }
        }
      }
    },
    Select: {
      variants: {
        borderlessSelect: {
          field: {
            backgroundColor: 'transparent',
            borderBottom: '1px solid #A5A7AA',
            color: '#ccc'
          }
        }
      }
    }
  },
  colors: {
    primary: '#66FCF1',
    bg: '#212832',
    bgFooter: '#4A2239',
    sliderIndicator: '#45A29E',
    sliderIndicatorActive: '#66FCF1',
    sectionTitle: '#66FCF1',
    sectionTitleFeature: '#fff',
    bgFeature: '#5EA09D',
    border: '#2C3F47',
    borderFeature: 'rgba(31, 40, 51, 0.2)',
    borderLight: '#A5A7AA',
    text: '#fff',
    textFeature: '#fff',
    navTextSecondary: '#66FCF1',
    articleCategory: '#66FCF1', // used to be #CB2D6F
    articleItemAuthor: '#ccc',
    articleSeeAll: '#66FCF1',
    articleText: '#bdc4c8',
    textSecondary: '#ccc',
    link: '#66FCF1',
    postDate: '#ccc',
    seeAllBorder: '#2C3F47',
    socialBackground: '#CB2D6F',
    loginText: '#66FCF1',
    menuProfile: '#1F2833',
    adminMenu: '#ccc'
  }
})

export { chineseTheme as theme }
