"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.theme = void 0;
var react_1 = require("@chakra-ui/react");
var breakpoints = ['30em', '48em', '62em', '80em'];
breakpoints.sm = breakpoints[0];
breakpoints.md = breakpoints[1];
breakpoints.lg = breakpoints[2];
breakpoints.xl = breakpoints[3];
var theme = (0, react_1.extendTheme)({
    textStyles: {
        navCategory: {
            fontWeight: '700',
            fontSize: {
                base: '28px'
            },
            lineHeight: {
                base: '48px'
            }
        },
        navCategoryItem: {
            fontSize: {
                base: '24px'
            },
            lineHeight: {
                base: '36px'
            }
        },
        navLink: {
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: {
                base: '13px'
            },
            color: 'textSecondary',
            lineHeight: {
                base: '16px'
            },
            textAlign: 'center',
            letterSpacing: '0.1em'
        },
        highlightTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '20px',
                md: '26px'
            },
            lineHeight: {
                base: '20px',
                md: '32px'
            }
        },
        highlightTitleCategory: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            letterSpacing: {
                base: '0.1em',
                md: '0.2em'
            },
            fontSize: {
                base: '14px'
            },
            lineHeight: {
                base: '17px'
            }
        },
        bannerTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '20px',
                md: '26px'
            },
            lineHeight: {
                base: '20px',
                md: '32px'
            }
        },
        bannerCategory: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '14px'
            },
            lineHeight: {
                base: '17px'
            },
            letterSpacing: '0.1em'
        },
        bigImageItemTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '20px',
                md: '20px'
            },
            lineHeight: {
                base: '26px'
            }
        },
        bigImageItemCategory: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '13px',
                md: '16px'
            },
            lineHeight: {
                base: '19px',
                md: '28px'
            },
            letterSpacing: '0.1em',
            textTransform: 'uppercase'
        },
        sectionTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '24px',
                md: '26px'
            },
            lineHeight: {
                base: '28px'
            }
        },
        sectionArticleTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '20px',
                md: '20px'
            },
            lineHeight: {
                base: '26px',
                md: '26px'
            }
        },
        sectionArticleSeeAll: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '18px',
                md: '20px'
            },
            lineHeight: {
                base: '26px'
            }
        },
        sectionArticleCategory: {
            textTransform: 'uppercase',
            fontStyle: 'normal',
            fontWeight: 'normal',
            letterSpacing: '0.1em',
            fontSize: {
                base: '13px'
            },
            lineHeight: {
                base: '28px'
            }
        },
        sectionArticleGridCategory: {
            textTransform: 'uppercase',
            fontStyle: 'normal',
            fontWeight: 'normal',
            letterSpacing: '0.1em',
            fontSize: {
                base: '13px'
            },
            lineHeight: {
                base: '20px',
                md: '26px'
            }
        },
        footerLink: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '18px'
            },
            lineHeight: '32px'
        },
        articleTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '28px',
                lg: '36px'
            },
            lineHeight: {
                base: '32px',
                lg: '42px'
            }
        },
        articleCategory: {
            textTransform: 'uppercase',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '14px'
            },
            lineHeight: {
                base: '18px'
            },
            letterSpacing: '0.1em'
        },
        articleIntro: {
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: {
                base: '26px',
                md: '28px'
            },
            lineHeight: {
                base: '32px',
                md: '36px'
            }
        },
        articleText: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '16px',
                md: '18px'
            },
            lineHeight: {
                base: '20px',
                md: '28px'
            }
        },
        collectionItemCategory: {
            fontStyle: 'normal',
            fontWeight: 'norma;',
            letterSpacing: '0.1em',
            fontSize: {
                base: '13px'
            },
            lineHeight: {
                base: '20px',
                md: '28px'
            }
        },
        collectionItemTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '18px',
                md: '20px'
            },
            lineHeight: {
                base: '20px',
                md: '26px'
            }
        },
        excerpt: {
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '16px'
            },
            lineHeight: {
                base: '20px'
            }
        },
        pageTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '40px',
                md: '56px'
            },
            lineHeight: {
                base: '48px',
                md: '64px'
            }
        },
        subTitle: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: {
                base: '18px'
            },
            lineHeight: {
                base: '72px'
            }
        },
        onBoardingTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '36px',
                md: '46px'
            },
            lineHeight: {
                base: '44px',
                md: '58px'
            }
        },
        onBoardingSubTitle: {
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            mt: '15px',
            fontSize: {
                base: '18px'
            },
            lineHeight: {
                base: '24px'
            }
        },
        formLabel: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '21px',
            lineHeight: '32px'
        },
        authorTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '32px',
                md: '32px'
            },
            lineHeight: {
                base: '32px',
                md: '32px'
            }
        },
        profileTitle: {
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: {
                base: '26px',
                lg: '55px'
            },
            lineHeight: {
                base: '30px',
                lg: '72px'
            }
        },
        articleItemAuthor: {
            fontFamily: 'Roboto',
            fontSize: '12px',
            lineHeight: '14px'
        }
    },
    sizes: {
        container: {
            xl: 1400
        }
    },
    styles: {
        global: {
            body: {
                fontFamily: 'GT America',
                backgroundColor: 'bg',
                color: 'text'
            }
        }
    },
    colors: {
        primary: '#1067F0',
        primaryRange: {
            500: '#1067F0',
            600: '#104ff0'
        },
        negative: '#E53E3E',
        bg: '#fff',
        bgFooter: '#F9FAFF',
        sliderIndicator: '#ccc',
        sliderIndicatorActive: '#000000',
        bgFeature: '#000000',
        border: '#EEEEEE',
        borderFeature: '#444444',
        borderLight: '#A5A7AA',
        text: '#314475',
        textFeature: '#fff',
        textSecondary: '#314475',
        link: '#1067F0',
        postDate: '#808083'
    },
    zIndices: {
        headroom: 1100,
        navExpand: 1400,
        menuProfile: 1300
    },
    components: {
        IconButton: {
            variants: {
                svg: {
                    display: 'inline-flex'
                }
            }
        },
        Checkbox: {
            sizes: {
                lg: {
                    control: {
                        width: 38,
                        height: 38,
                        fontSize: 30
                    },
                    label: {
                        fontSize: '21px',
                        lineHeight: '32px',
                        marginLeft: '20px'
                    }
                }
            },
            baseStyle: {
                control: {
                    border: '1px solid black'
                }
            }
        },
        Button: {
            baseStyle: {
                borderRadius: 0
            },
            variants: {
                outlineKr: {
                    padding: '16px 25px',
                    height: 'auto',
                    border: '1px solid #cccc',
                    _hover: {
                        color: 'white',
                        bg: 'primary'
                    }
                },
                lg: {
                    paddingLeft: '30px',
                    pr: '30px',
                    color: 'white',
                    backgroundColor: 'primary',
                    height: '43px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '43px'
                },
                lgBorder: {
                    paddingLeft: '30px',
                    pr: '30px',
                    color: 'primary',
                    backgroundColor: 'transparent',
                    border: '1px solid',
                    borderColor: 'primary',
                    height: '42px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '20px',
                    lineHeight: '42px',
                    _hover: {
                        color: 'white',
                        backgroundColor: 'primary'
                    },
                    _focus: {
                        color: 'white',
                        backgroundColor: 'primary'
                    }
                },
                lgIcon: {
                    border: '1px solid',
                    borderColor: 'primary',
                    minWidth: '60px',
                    color: 'primary',
                    backgroundColor: 'transparent',
                    height: '43px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    fontSize: '24px',
                    lineHeight: '43px',
                    _focus: {
                        color: 'white',
                        backgroundColor: 'primary'
                    },
                    _hover: {
                        color: 'white',
                        backgroundColor: 'primary'
                    }
                }
            }
        },
        FormLabel: {
            baseStyle: {
                fontStyle: 'normal',
                fontWeight: 'bold',
                fontSize: '18px',
                lineHeight: '28px'
            },
            variants: {
                borderless: {
                    fontSize: '18px',
                    lineHeight: '28px'
                }
            }
        },
        Input: {
            variants: {
                borderlessFooter: {
                    field: {
                        backgroundColor: 'transparent',
                        mt: '20px',
                        maxHeight: '45px',
                        marginTop: 15,
                        fontSize: '24px',
                        lineHeight: '32px',
                        paddingLeft: 0,
                        paddingBottom: '16px',
                        borderBottom: '1px solid #1067F0'
                    }
                },
                borderlessHeader: {
                    field: {
                        backgroundColor: 'transparent',
                        mt: '20px',
                        maxHeight: '45px',
                        marginTop: 15,
                        fontSize: '24px',
                        lineHeight: '32px',
                        paddingLeft: 0,
                        paddingBottom: '16px',
                        color: 'rgba(255, 255, 255, 0.4)',
                        borderBottom: '1px solid #FFF',
                        _hover: {
                            borderColor: '#FFF'
                        }
                    }
                },
                borderless: {
                    field: {
                        mt: '20px',
                        maxHeight: '45px',
                        marginTop: '5px',
                        fontSize: '21px',
                        lineHeight: '32px',
                        paddingLeft: 0,
                        paddingBottom: 11,
                        borderBottom: '1px solid #A5A7AA'
                    }
                }
            },
            sizes: {
                md: {
                    field: {
                        borderRadius: 0,
                        height: '45px',
                        _hover: {
                            borderColor: 'primary'
                        }
                    }
                }
            }
        },
        Select: {
            variants: {
                borderlessSelect: {
                    field: {
                        mt: '20px',
                        maxHeight: '45px',
                        minHeight: '45px',
                        marginTop: '5px',
                        fontSize: '21px',
                        lineHeight: '32px',
                        paddingLeft: 0,
                        paddingBottom: 11,
                        borderBottom: '1px solid #A5A7AA'
                    }
                }
            },
            sizes: {
                md: {
                    field: {
                        height: '40px'
                    }
                }
            }
        },
        Tabs: {
            baseStyle: {},
            variants: {
                kr: {
                    tablist: {
                        borderBottom: '1px solid #A5A7AA'
                    },
                    tab: {
                        padding: '10px 0',
                        marginRight: '30px',
                        fontFamily: 'Roboto',
                        fontSize: '20px',
                        _selected: {
                            borderBottom: '1px solid #000000'
                        }
                    }
                }
            }
        }
    }
});
exports.theme = theme;
