import { useRouter } from 'next/router'
import { useEffect } from 'react'

function useRouterRedirect() {
  const router = useRouter()
  const { pathname, query } = router

  useEffect(() => {
    if (pathname === '/') {
      if (query.s || query.attachment_id || query.p) {
        router.push('/')
      }
    }
  }, [pathname, query])
}
export default useRouterRedirect
